/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Segment, Image, Grid, List, Button } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { asyncConnect } from '@plone/volto/helpers';
import { UniversalLink, Icon } from '@plone/volto/components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Helmet, toBackendLang } from '@plone/volto/helpers';
import { Link } from 'react-router-dom';
import { getNavigation } from '@plone/volto/actions';
import config from '@plone/volto/registry';
import ReactGA from "react-ga4";
import 'remixicon/fonts/remixicon.css'

class Footer extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getNavigation: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { settings } = config;
    if (settings.isMultilingual) {
      this.props.getNavigation(`${toBackendLang(this.props.lang)}`, 4);
    } else {
      this.props.getNavigation('', 4);
    }
  }
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  renderItems = (items) => {
    return (
      <ul id="snd_column">
        {items.map((item) => (
          <li
            key={item.url}
            className={item.items?.length > 0 ? 'with-children' : ''}
          >
            <Link to={item.url}>{item.title}</Link>
            {/* {item.items && this.renderItems(item.items)} */}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { items } = this.props;
    const year = new Date().getFullYear();
    const { settings } = config;
    const ga_key = config?.settings?.ga4_key;
    if (ga_key){
      ReactGA.initialize(ga_key);
    }
    return (
      <Segment
        role="contentinfo"
        vertical
        padded
        // inverted
        // color="grey"
        textAlign="center"
        id="footer"
        style={{ 'paddingTop': '5em' }}
      >
        <Container>
          <Grid stackable columns={3}>
            <Grid.Column width={5} style={{ 'textAlign': 'initial' }}>
              <p>
                <strong>
                  General inquiries
                  <br />
                </strong>
                +1 902 494 4101
                <br />
                otn@dal.ca
                <br />
              </p>
              <p>
                <strong>
                  Data Centre
                  <br />
                </strong>
                otndc@dal.ca
                <br />
              </p>
              <p>
                <strong>
                  Media Contact
                  <br />
                </strong>
                anja.samardzic@dal.ca
              </p>
              <br />
              <div>
                <a
                  className="boxlink"
                  href="https://mailchi.mp/878c3b54d129/9k6r7u7l6l"
                >
                  Join the OTN e-list
                </a>
              </div>
            </Grid.Column>
            <Grid.Column width={4} style={{ 'textAlign': 'initial' }}>
              <strong>Go to...</strong>
              {items && this.renderItems(items)}
            </Grid.Column>
            <Grid.Column width={3} style={{ 'textAlign': 'initial' }}>
              <Grid.Row style={{ 'marginBottom': '29.7px' }}>
                <Grid columns={8}>
                  <Grid.Column></Grid.Column>
                  <Grid.Column>
                    <a href="https://bsky.app/profile/oceantracking.bsky.social"
                       title="OTN on Bluesky">
                    <Button icon size='tiny' color='blue'>
                      <i class="ri-bluesky-fill"></i>
                    </Button>
                    </a>
                  </Grid.Column>
                  <Grid.Column>
                    <a href="https://x.com/OceanTracking"
                       title="OTN on X (formerly known as Twitter)">
                    <Button icon size='tiny' color='black'>
                      <i class="ri-twitter-x-line"></i>
                    </Button>
                    </a>
                  </Grid.Column>
                  <Grid.Column>
                    <a href="https://facebook.com/oceantrackingnetwork"
                       title="OTN on Facebook">
                    <Button icon size='tiny' color='facebook'>
                      <i class="ri-facebook-line"></i>
                    </Button>
                    </a>
                  </Grid.Column>
                  <Grid.Column>
                    <a href="https://www.youtube.com/user/OceanTrackingNetwork"
                      title="OTN on YouTube">
                    <Button icon size='tiny' color='youtube'>
                      <i class="ri-youtube-line"></i>
                    </Button>
                    </a>
                  </Grid.Column>
                  <Grid.Column>
                    <a href="https://www.linkedin.com/company/OceanTrackingNetwork"
                       title="OTN on Linkedin">
                    <Button icon size='tiny' color='linkedin'>
                      <i class="ri-linkedin-line"></i>
                    </Button>
                    </a>
                  </Grid.Column>
                  <Grid.Column>
                    <a href="https://www.instagram.com/oceantrackingnetwork"
                       title="OTN on Instagram">
                    <Button icon size='tiny' color='pink'>
                      <i class="ri-instagram-line"></i>
                    </Button>
                    </a>
                  </Grid.Column>
                  <Grid.Column></Grid.Column>
                </Grid>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div style={{ 'marginBottom': '29.7px', display: 'block' }}>
                    <UniversalLink href="https://obis.org/node/68f83ea7-69a7-44fd-be77-3c3afd6f3cf8">
                      <Image
                        src={
                          settings.apiPath +
                          `/media/logos/obis_new_logo_white.png/@@images/image/mini`
                        }
                        style={{
                          'marginLeft': 'auto',
                          'marginRight': 'auto',
                        }}
                        title="Ocean Biodiversity Information System - OBIS"
                      />
                    </UniversalLink>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div style={{ display: 'block' }}>
                    <UniversalLink href="https://marinelife2030.org/">
                      <Image
                        src={
                          settings.apiPath +
                          `/media/logos/one-color-logo-white-70-e1662572782950.png/@@images/image/mini`
                        }
                        style={{
                          'marginLeft': 'auto',
                          'marginRight': 'auto',
                        }}
                        title="Marine Life 2030"
                      />
                    </UniversalLink>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div style={{ display: 'block' }}>
                    <UniversalLink href="https://coretrustseal.org/">
                      <Image
                        src={
                          settings.apiPath +
                          `/media/logos/coretrust_logo.png/@@images/image/mini`
                        }
                        style={{
                          'marginLeft': 'auto',
                          'marginRight': 'auto',
                        }}
                        title="Core Trust Seal"
                      />
                    </UniversalLink>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid>
          <div style={{ 'textAlign': 'initial' }}>
            © {year} Ocean Tracking Network
          </div>
        </Container>
      </Segment>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      items: state.navigation.items,
      lang: state.intl.locale,
    }),
    { getNavigation },
  ),
  asyncConnect([
    {
      key: 'navigation',
      promise: ({ location, store: { dispatch, getState } }) => {
        const { settings } = config;
        const lang = getState().intl.locale;
        if (settings.isMultilingual) {
          return (
            __SERVER__ && dispatch(getNavigation(`${toBackendLang(lang)}`, 4))
          );
        } else {
          return __SERVER__ && dispatch(getNavigation('', 4));
        }
      },
    },
  ]),
)(Footer);
